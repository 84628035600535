import Querys from '../querys.js'

export default ({
    props: { integrationConfig: Object },

    data: () => ({
        usarViewPadrao: true,
        integrarPontos: true,
        integrarClientes: true,
        useLocal: true,
        validateForm: {},
        configFiliais: null,
        filialName: '',
        rulePointEstablishment: null,
        pointsRuleLabel: "",
        integrationForm: {
            clientDatabase: {
                databaseName: '',
                databaseType: '',
                host: '',
                user: '',
                password: '',
                port: '',
                clientQuery: '',
                pointQuery: '',
                codeQuery: '',
                controlCode: '',
                clientControlCode: ''
            },
            establishment: {
                tokenDonuz: JSON.parse(atob(sessionStorage.getItem('tokenDonuz'))),
                establishmentId: atob(sessionStorage.getItem('establishmentId'))
            },
            delay: 60,
            integrationType: "",
            local: false,
            lastUpdate: "",
            integrationType: 'Database',
            cpfIsPassword: true,
            datetimeOffset: -3,
            filialCode: "",
            pointRule: "",
            isRunning: false
        },

    }),
    async created() {
        if (this.isUpdate()) {
            var response = await this.DONUZ.Integration.GetIntegrationById(this.$route.params.id);
            this.integrationForm = response;
            this.useLocal = this.integrationForm.local == true ? 'Local' : 'Remoto'
            this.integrarPontos = this.integrationForm.clientDatabase.pointQuery != ''
            this.integrarClientes = this.integrationForm.clientDatabase.clientQuery != ''
        } else if (this.integrationConfig) {
            this.integrationForm = this.integrationConfig;
        }

        this.configurationFilial();
        const selectedFilial = this.configFiliais.find((f) => f.id_filial == this.integrationForm.filialCode);
        this.filialName = selectedFilial ? selectedFilial.filial : "Filial";

        this.pointsRuleLabel = "Regra de pontos";
        await this.rulesEstablishment();
        const selectedPointsRule = this.rulePointEstablishment.find(i => i.id_regra == this.integrationForm.pointRule);
        if (selectedPointsRule) {
            this.pointsRuleLabel = selectedPointsRule.nome_regra ?? "";
        }

        this.validateForm = {
            required: [v => !!v || 'Campo obrigatório'],
            delay: [v => (v && v >= 30) || 'Tempo mínimo de 30 minutos'],
        }
    },
    methods: {
        isUpdate() {
            return !!this.$route.params.id;
        },
        async onSave() {
            if (!this.$refs.form.validate()) {
                this.$toasted.global.error({ msg: 'Preencha todos os campos' })
                return;
            }
            if (this.usarViewPadrao) {
                this.integrationForm.clientDatabase.clientQuery = Querys.cliente;
                this.integrationForm.clientDatabase.pointQuery = Querys.pontos;
            }
            if (!this.integrarPontos) {
                this.integrationForm.clientDatabase.pointQuery = '';
            }
            if (!this.integrarClientes) {
                this.integrationForm.clientDatabase.clientQuery = ''
            }
            this.integrationForm.local = this.useLocal == 'Remoto' ? false : true;

            if (this.isUpdate())
                return await this.update();

            this.nextStep();
        },
        async update() {
            let response = await this.DONUZ.Integration.UpdateIntegration(this.integrationForm);
            if (response.statusCode == 200) {
                this.$toasted.global.success();
                location.href = '/database/list'
            }
        },
        async configurationFilial() {
            var estabelecimento = atob(sessionStorage.getItem('establishmentId'));
            var result = JSON.parse(sessionStorage.getItem(`filiais-${estabelecimento}`));
            if (result == null) {
                const resp = await this.APPDONUZ.AppDonuzIntegration.GetIdFilial();
                if (resp.status == 200)
                    this.configFiliais = resp.filiais
                sessionStorage.setItem(`filiais-${estabelecimento}`, JSON.stringify(resp))
            }
            this.configFiliais = result.filiais;
        },
        async rulesEstablishment() {
            var estabelecimento = atob(sessionStorage.getItem('establishmentId'));
            var result = JSON.parse(sessionStorage.getItem(`rules-${estabelecimento}`));
            if (result != null) {
                this.rulePointEstablishment = result.regras
                return;
            }
            const resp = await this.APPDONUZ.AppDonuzIntegration.GetRulesEstablishment();
            if (resp.status != 200) return;
            this.rulePointEstablishment = resp.regras
            sessionStorage.setItem(`rules-${estabelecimento}`, JSON.stringify(resp))
        },
        nextStep() {
            this.$emit("next-step", this.integrationForm);
        },
    }
})