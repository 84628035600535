export default ({
    props: { integrationConfig: Object },

    data: () => ({
        viewResult: {
            header: [],
            registers: [],
            loading: false,
            error: false,
            errorCount: 0,
        },
        viewOk: false,
    }),

    async created() {
        await this.udpateViewResult();
    },

    methods: {
        previousStep() {
            this.$emit("previous-step");
        },

        nextStep() {
            this.$emit("next-step");
        },

        async save() {
            let response = await this.DONUZ.Integration.PostIntegration(this.integrationConfig);
            if (response.statusCode == 201) {
                this.$toasted.global.success()
                this.nextStep();
            }
        },

        async udpateViewResult() {
            this.viewResult.errorCount = 0;
            let queryUpper = true;
            let requestCounter = 0;
            while (requestCounter < 2) {
                try {
                    requestCounter++;
                    this.viewResult.loading = true;
                    const response = await this.DONUZ.Integration.GetViewResult({
                        clientDatabase: this.integrationConfig.clientDatabase,
                    }, queryUpper);
                    this.viewResult.loading = false;
                    
                    if (response.status != 200) throw new Error();
    
                    const csv = await response.text();
                    this.processCsv(csv);
                    return this.viewResult.error = false;
                } catch (error) {
                    queryUpper = !queryUpper;
                    this.viewResult.errorCount++;
                }
            }
            this.viewResult.loading = false;
            this.viewResult.error = true;
        },

        processCsv(csv) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
            const lines = csv.split("\n");
            this.viewResult.header = lines[0].split(";");
            this.viewResult.registers = lines
                .splice(1, lines.length - 2)
                .map((line) => line.split(";"))
                .map((line) => {
                    if (line[0])
                        line[0] = this.formatCpf(line[0]);
                    return line;
                })
                .map((line) => {
                    if (line[1])
                        line[1] = formatter.format(line[1]);
                    return line;
                });
        },

        formatCpf(v) {
            v = v.replace(/\D/g, "");
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
            return v;
        }
    }
})